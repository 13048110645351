







































































import Vue from 'vue'
import { ObjectListObject, SharedConstants } from '@knitiv/api-client-javascript'
import { DataTableHeader } from 'vuetify'
import ClassViewerList from '@/components/importfile/class-viewer-list.vue'
import ImportTemplateDialog, { ImportType } from '@/components/importfile/modal-import-template.vue'
import { api } from '@/utils/api'
import DnDZone from '@/components/dnd-zone.vue'
import { getFileExtension } from '@/utils'
import { downloadRaw } from '@/utils/download'

type enhancedObject = ObjectListObject & { kid: string }

interface Data {
  templatesKid: any;
  headers: DataTableHeader[];

  dataSources: enhancedObject[]
}

export default Vue.extend({
  components: {
    ClassViewerList,
    DnDZone,
  },
  data(): Data {
    return {
      templatesKid: SharedConstants.nodes.DATAEXCH_TEMPLATE,
      headers: [
        { text: 'Nom', value: 'name' },
        { text: 'KID', value: 'kid' },
        { text: 'Actions', value: 'action', align: 'end' },
      ],

      dataSources: [],
    }
  },

  methods: {
    async exportTemplate(kid: string) {
      const object = await api().objectInfo({
        kid,
      })

      downloadRaw(`${object.objname}.kimp`, 'text/json', object.kjson)
    },

    onFilesDroppped(files: File[]) {
      const file = files[0]

      console.log('file', file)

      const extension = `.${getFileExtension(file.name)}`

      console.log('extension', extension)

      if (file && ['.json', '.kimp'].includes(extension)) {
        this.onImport(file, (extension as ImportType['extFilter']))
      } else {
        console.error('Bad file')
      }
    },

    async onImport(file?: File, type?: ImportType['extFilter']) {
      const answer = await this.$dialog.open(ImportTemplateDialog, {
        component: {
          file,
          type,
        },
      })
      if (answer !== 'close') {
        // @ts-ignore
        this.$refs.list.refresh()
      }
    },
  },
})
