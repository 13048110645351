export const downloadRaw = (filename: string, mime: string, content: string) => {
  const dataStr = `data:${mime};charset=utf-8,${encodeURIComponent(content)}`

  const a = document.createElement('a')
  a.style.display = 'none'
  a.href = dataStr
  a.download = `${filename}`
  document.body.append(a)
  a.click()
}
