var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DnDZone',{staticClass:"full-height",on:{"files-dropped":_vm.onFilesDroppped}},[_c('v-container',{staticClass:"full-height"},[_c('class-viewer-list',{ref:"list",staticClass:"full-width",attrs:{"title":"","class-id":_vm.templatesKid,"delete-action":"","confirm-on-delete":""},scopedSlots:_vm._u([{key:"header-buttons",fn:function(){return [_c('span',[_c('v-btn',{attrs:{"text":"","to":"/importfile/templates/new"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Nouveau ")],1),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.onImport()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-file-upload ")]),_vm._v("Importer ")],1)],1)]},proxy:true},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.exportTemplate(item.kid)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-export ")])],1)]}}],null,true)},[_c('span',[_vm._v("Exporter le modèle")])])]}},{key:"name",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":("/importfile/templates/edit/" + (item.kid))}},[_vm._v(" "+_vm._s(value)+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }