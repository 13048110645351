





























































































import Vue, { PropType } from 'vue'
import {
  FileUpload, ObjectCreateItem, ObjectCreateOutput, SharedConstants,
} from '@knitiv/api-client-javascript'
import { nanoid } from 'nanoid'
import { InjectAPI } from '@/utils/api'
import {
  BackImportTemplate,
  BackImportTemplateValidator,
  ImportFileObjectComponentLink,
  ImportFileObjectComponentRepre,
  SavedImportTemplate,
  SavedImportTemplateValidator,
} from '@/models/imports'

function* getNextNR(): IterableIterator<string> {
  let counter = 1

  while (true) {
    yield `K_NODE;_NR_${counter.toString().padStart(6, '0')}`
    counter += 1
  }
}

export interface ImportType {
  text: string;
  extFilter: '.kimp' | '.json';
}

interface Data {
  importName: string
  uploadPercent: number
  isUploading: boolean
  uploadedNode: string
  selectedFile: File | undefined
  isDone: boolean
  isValidating: boolean;

  importType: ImportType['extFilter']
  importTypes: ImportType[]
}

const importTypes: ImportType[] = [
  { text: 'Fichier brut (.json)', extFilter: '.json' },
  { text: 'Export interface (.kimp)', extFilter: '.kimp' },
]

export default Vue.extend({
  components: {},
  mixins: [
    InjectAPI,
  ],
  props: {
    resolve: {
      type: Function as PropType<(arg: any) => any>,
      required: true,
    },
    reject: {
      type: Function as PropType<(arg: any) => any>,
      required: true,
    },
    file: {
      type: File,
      required: false,
      default: undefined,
    },
    type: {
      type: String as PropType<ImportType['extFilter']>,
      required: false,
      default: undefined,
    },
  },
  data(): Data {
    return {
      importName: '',
      uploadPercent: 0,
      isUploading: false,
      uploadedNode: '',
      isDone: false,
      isValidating: false,
      selectedFile: this.file,

      importTypes,
      importType: this.type ?? importTypes[0].extFilter,
    }
  },
  mounted() {
    console.log('this.file', this.file)
    console.log('this.selectedFile', this.selectedFile)
  },
  methods: {
    makeGraph(objects: SavedImportTemplate['objects']): SavedImportTemplate['graph'] {
      console.log('objects', objects)

      const graph: SavedImportTemplate['graph'] = {
        nodes: [],
        edges: [],
        positions: {},
      }

      Object.entries(objects).forEach(([objectName, object]) => {
        const { componants, ...rest } = object

        graph.nodes.push({
          ...rest,
          representations: [],
          id: objectName,
          class: {
            kid: object.class,
          },
          action: object.action ?? 'create/update',
          exitIfFound: false,
          type: `${object.class}::0`,
        })

        for (const component of componants) {
          if (this.isRepre(component)) {
            graph.nodes.find((node) => node.id === objectName)?.representations.push(component)
          }
        }
      })

      Object.entries(objects).forEach(([objectName, object]) => {
        const { componants } = object

        for (const component of componants) {
          if (this.isLink(component)) {
            const edgeId = nanoid()
            graph.edges.push({
              source: objectName,
              target: component.key ?? '',
              subtype: component.subtype,
              id: edgeId,
            })
          }
        }
      })

      const positions: SavedImportTemplate['graph']['positions'] = {}
      graph.nodes.forEach((node) => {
        positions[node.id] = [0, 0]
      })

      graph.positions = positions

      return graph
    },
    isRepre(link: ImportFileObjectComponentLink | ImportFileObjectComponentRepre): link is ImportFileObjectComponentRepre {
      return (link.type === 'KREPRE')
    },
    isLink(link: ImportFileObjectComponentLink | ImportFileObjectComponentRepre): link is ImportFileObjectComponentLink {
      return (link.type === 'KLINK')
    },
    resetModal() {
      this.importName = ''
      this.uploadPercent = 0
      this.isUploading = false
      this.uploadedNode = ''
      this.selectedFile = undefined
      this.isDone = false
      this.isValidating = false
    },

    async checkIfNameIsTaken() {
      if (!this.importName) {
        throw new Error('Import file name not specified')
      }

      let result: boolean

      this.isValidating = true

      try {
        const response = await this.$api.objectExist({ objectClass: SharedConstants.nodes.DATAEXCH_TEMPLATE, name: this.importName, links: [] })
        console.log('response', response)

        console.log('response.RESULT_COUNT', response.result.RESULT_COUNT)
        if (response.result.RESULT_COUNT === 0) {
          result = false
        } else {
          this.$toast.error('Ce nom est déjà utilisé !')
          result = true
        }
      } catch (e) {
        this.$toast.error('Une erreur est survenue lors de la vérification du nom')
        result = true
        console.error(e)
      }

      this.isValidating = false
      return result
    },

    readFile(file: File): Promise<string> {
      return new Promise((resolve, reject) => {
      // Check if the file is an image.
        if (file.type && file.type !== 'application/json') {
          console.log('File is not JSON.', file.type, file)
          return
        }

        const reader = new FileReader()
        reader.onload = () => {
          resolve(reader.result as string)
        }
        reader.onerror = reject

        reader.readAsText(file)
      })
    },

    async uploadFile(): Promise<ObjectCreateOutput | null> {
      if (!this.selectedFile) {
        throw new Error('No file selected')
      }

      const [, extension] = this.selectedFile.name.split('.')

      const buffer = this.selectedFile as Blob

      const fileUpload: FileUpload = {
        extension: `.${extension}`,
        size: this.selectedFile.size,
        name: this.importName,
        buffer,
      }

      const { filename, id } = await this.$api.upload({
        file: fileUpload,
        onProgress: (progress) => {
          console.log('progress', progress)
          this.uploadPercent = progress.progress
        },
      })

      const nr: string = getNextNR().next().value

      const rawJson = await this.readFile(this.selectedFile)

      let json
      if (this.importType === '.json') {
        const backJson = JSON.parse(rawJson) as BackImportTemplate

        const parsed = BackImportTemplateValidator.safeParse(backJson)

        console.log('parsed', parsed)

        const _json: SavedImportTemplate = {
          name: this.importName,
          graph: this.makeGraph(backJson.objects),
          version: '1',
          data_modifiers: [],
          header_filters: backJson.header_filters,
          worksheet_id: backJson.worksheet_id,
          headers_line: backJson.headers_line,
          start_line: backJson.start_line,
          objects: backJson.objects,
        }

        backJson.data_modifiers?.forEach((data_modifier, index) => {
          if (backJson?.data_modifiers?.[index]) {
            _json.data_modifiers.push({
              ...data_modifier,
              id: nanoid(),
            })
          }
        })

        json = _json

        const parsedTemplate = SavedImportTemplateValidator.safeParse(json)
        console.log('parsedTemplate', parsedTemplate)
      } else if (this.importType === '.kimp') {
        json = JSON.parse(rawJson) as SavedImportTemplate

        const parsedTemplate = SavedImportTemplateValidator.safeParse(json)
        console.log('parsedTemplate', parsedTemplate)
      }

      const item: ObjectCreateItem = {
        target_objnum: nr,
        OBJECT_LIST: {
          [nr]: {
            isa: SharedConstants.nodes.DATAEXCH_TEMPLATE,
            fileid: id,
            kjson: JSON.stringify(json),
            REPRE_LIST: [
              {
                name: filename,
                lang: SharedConstants.representations.UNIVERSEL,
              },
            ],
          },
        },
      }
      return this.$api.objectCreate({
        items: [item],
      })
    },

    async importFile() {
      try {
        const taken = await this.checkIfNameIsTaken()

        if (taken) {
          return
        }

        this.uploadPercent = 0

        this.isUploading = true
        const upload = await this.uploadFile()
        this.isUploading = false
        this.isDone = true
        if (upload) {
          const objIds = Object.values(upload.result)
          this.uploadedNode = objIds[0].objid // We only have 1 anyway
        }
      } catch (e) {
        this.resolve('closed')
        console.error(e)
      }
    },
  },
})
